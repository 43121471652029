import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFound = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />

      <div className="intro">
        <div className="shell">
          <div className="intro__inner">
            <div className="intro__content">
              <h1 style={{ textAlign: "center" }}>NOT FOUND</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
